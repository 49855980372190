import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Title from "../../elements/Title"
import RecoSlider from "../../elements/PostRecoSlider"

import Para from "../../elements/Paragraph"
const PostContentBlock = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  width: 100%;
  padding-top: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 3%;
  & .fifty-fifty .wp-block-image {
    // width:100%;
    // max-width:100%;
    // height:auto !important;
  }
  & .fifty-fifty .wp-block-image img {
    // width:100%;
    // max-width:100%;
    // height:auto !important;
  }
  & figcaption {
    font-size: 16px;
  }
  & h1 {
    font-size: 2.5em;
    font-weight: bold;
    line-height: 1.2;
    color: #000;
    z-index: 99;
    width: 80%;
    margin: auto;
    margin-bottom: 0.5em;
  }
  & h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.2;
    width: 80%;
    margin: auto;
    margin-bottom: 1em;
  }
  & iframe {
    max-width: 100%;
    margin: auto;
  }
  & h4 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
    width: 80%;
    margin: auto;
    margin-bottom: 1em;
  }
  & ul {
    width: 80%;
    margin: auto;
    padding-top: 1%;
  }
  & figure.wp-block-image.size-large {
    // width:100%;
    max-width: 80%;
    margin: auto;
  }
  & figure.wp-block-image {
    // width:100%;
    max-width: 80%;
    margin: auto;
  }
  & .wp-block-image.alignfull {
    // width:100%;
    max-width: 80%;
    margin: auto;
  }
  & .wp-block-image.is-style-default {
    max-width: 80%;
    margin: auto;
  }
  & .wp-block-group figure {
    max-width: 80%;
    margin: auto;
  }
  & .wp-block-image figure {
    max-width: 80%;
    margin: auto;
  }
  // & .wp-block-image{
  //     width:100%;
  //     margin:3% auto 3% auto;
  // }
  & .wp-block-image img {
    //    height:auto!important;
    //    width:100%;
    margin-bottom: 20px;
  }
  & figure.wp-block-table {
    // width:100%;
    max-width: 80%;
    margin: auto;
  }
  & figure {
    margin-top: 2em;
  }

  & ol {
    width: 80%;
    margin: auto;
    padding-top: 1%;
  }
  & ol li {
    margin-left: 2%;
  }
  & p {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  & .post-title {
    width: 80%;
    font-size: 3em;
    color: #000;
    text-align: left;
    line-height: 1.23;
    margin-bottom: 0.5em;
  }
  & .post-title-journal {
    width: 70%;
    font-size: 3em;
    color: #000;
    text-align: left;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 0px;
  }
  & .post-title-journal span {
    background-color: #fff;
    box-decoration-break: clone;
    padding: 0px 50px 0px 15px;
  }
  & .wp-block-cover {
    // width:100%;
    margin-bottom: 3.5em;
    margin-top: 5px;
    height: 600px;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 3rem;
  }
  & .gatsby-image-wrapper {
    max-height: 600px;
    // width:100%;
    margin: auto;
  }
  @media screen and (min-width: 1920px) {
    & .wp-block-cover {
      width: 100%;
      height: 50vh;
    }
    & .post-title-journal {
      width: 50%;
    }
  }
  @media screen and (max-width: 991px) {
    padding-top: 80px;
    & .post-title {
      width: 90%;
      font-size: 2.3em;
    }
    & .post-title-journal {
      width: 90%;
      font-size: 2.3em;
    }
    & h1 {
      font-size: 2.3em;
    }
    & iframe.xd {
      max-height: 500px;
    }
  }
  @media screen and (max-width: 767px) {
    & .wp-block-cover {
      padding: 20px;
    }
  }
  @media screen and (max-width: 479px) {
    padding-top: 8vw;
    font-size: 15px;
    & .wp-block-video video {
      width: 100%;
    }
    & .post-title {
      font-size: 30px;
      width: 100%;
    }
    & .post-title-journal {
      font-size: 30px;
      width: 100%;
    }
    & .post-title-journal span {
      padding: 0px 10px 0px 10px;
    }
    & h1 {
      width: 100% !important;
      font-size: 2em;
    }
    & h2 {
      width: 100%;
      font-size: 22px;
    }
    & h4 {
      width: 100%;
      font-size: 18px;
    }
    & ul {
      width: 100%;
    }
    & .wp-block-image.alignfull {
      max-width: 100%;
      margin: auto;
    }
    & .wp-block-image {
      width: 100%;
      margin: 3% auto 3% auto;
    }

    .wp-block-image figure {
      max-width: 100%;
      margin: auto;
    }
    & figure.wp-block-image {
      width: 100%;
      max-width: 100%;
      margin: auto;
    }
    & figure.wp-block-table {
      width: 100%;
      max-width: 100%;
      margin: auto;
    }
    & figure {
      margin-top: 2em;
    }
    & figure.wp-block-image.size-large {
      width: 100%;
      max-width: 100%;
      margin: auto;
    }
    & iframe.xd {
      max-height: 300px;
    }
    & ol {
      width: 100%;
      margin: auto;
      padding-top: 1%;
    }
    & ol li {
      margin-left: 5%;
    }
    & p {
      width: 100%;
      font-size: 15px;
    }

    & .post-title {
      width: 100%;
      margin-bottom: 0.5em;
    }
    & .wp-block-cover {
      margin-bottom: 3.5em;
      height: 375px;
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      margin-top: 2rem;
    }
    & .gatsby-image-wrapper img {
      object-fit: cover !important;
    }
    .wp-block-image.is-style-default {
      max-width: 100%;
      margin: auto;
    }
    .wp-block-group figure {
      max-width: 100%;
      margin: auto;
    }
  }
`
const TagWrap = styled.div`
  display: block;
  margin: auto;
  width: 80%;
  margin-bottom: 1.45em;
  @media screen and (max-width: 479px) {
    width: 100%;
  }
`
const PostInnerContainer = styled.div`
  width: 100%;
  @media screen and (max-width: 479px) {
    width: 90%;
  }
`
const Post = props => {
  return (
    <>
      <div className="w-full">
        <PostContentBlock>
          <div
            className={`wp-block-cover ${
              props.tint ? "has-background-dim" : ""
            }`}
            style={{ backgroundImage: `url(${props.featuredImage})` }}
          >
            {props.postCategory === "Journal" && (
              <h1 className="post-title-journal">
                <span>{props.title}</span>
              </h1>
            )}
          </div>
          <PostInnerContainer>
            {props.postCategory === "Case Study" && (
              <h1 className="post-title">
                <span>{props.title}</span>
              </h1>
            )}
            <TagWrap>
              {props.tags.nodes.map(({ node }, index) => (
                <Link
                  to={"/tag/" + props.tags.nodes[index].slug + "/"}
                  className="text-deco-none mr-4 tag-links"
                  style={{ width: "auto" }}
                >
                  <Para
                    size="md"
                    key={index}
                    width="fit-content !important"
                    LineHeight="1.6"
                    marginBottom="0"
                    fontWeight="normal"
                  >
                    #{props.tags.nodes[index].name}
                  </Para>
                </Link>
              ))}
            </TagWrap>
            <div
              dangerouslySetInnerHTML={{ __html: props.data.content }}
              style={{ width: "100%" }}
            />
          </PostInnerContainer>
        </PostContentBlock>
      </div>
      <div className="bodyBlack" style={{ padding: "80px 0 80px 0" }}>
        <div className="w-90">
          <Title size="lg" color="white" weight="bold" bottom="2rem">
            read more like this...
          </Title>
        </div>
        <div style={{ width: "95%", margin: "auto" }}>
          <RecoSlider
            postCategory={props.postCategory}
            title={props.title}
          ></RecoSlider>
        </div>
      </div>
    </>
  )
}

export default Post
