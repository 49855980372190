import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from "../views/Post/Post"

export default function PostPage({ data, pageContext }) {
  const post = data.allWpPost.nodes[0]
  // const seoImage = post.featuredImage.node.mediaItemUrl
  const { postCategory, postLink } = pageContext
  const title = post.title
  const seoImage = post.featuredImage.node.mediaItemUrl
  const tags = post.tags
  const baseUrl = "https://www.kwazi.in/"
  return (
    <Layout className="headerOld">
      <SEO
        title={post.title}
        image={
          post.summary.tileImage
            ? post.summary.tileImage.mediaItemUrl
            : seoImage
        }
        whatsappImage={
          post.summary.whatsappImage
            ? post.summary.whatsappImage.mediaItemUrl
            : seoImage
        }
        url={baseUrl + postLink}
        description={post.summary.summary.replace(/(<([^>]+)>)/gi, "")}
        isPost={true}
      />
      <Post
        data={post}
        postCategory={postCategory}
        title={title}
        featuredImage={seoImage}
        tags={tags}
        tint={true}
      ></Post>
    </Layout>
  )
}
export const query = graphql`
  query ($slug: String) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        id
        content
        date
        slug
        excerpt
        summary {
          summary
          tileImage {
            mediaItemUrl
          }
          whatsappImage {
            mediaItemUrl
            altText
          }
        }
        categories {
          nodes {
            slug
            name
          }
        }
        tags {
          nodes {
            slug
            name
          }
        }
        featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
    }
  }
`
