import React, { useRef } from 'react'
import { graphql, StaticQuery, Link } from "gatsby";
import styled, { css } from 'styled-components';
import Slide from "../elements/Slide"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay, A11y } from 'swiper';
/// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
const ArrowHolderLeft = styled.div`
    color:white;
    width:30px;
    height:30px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    position:absolute;
    top:50%;
    left:0px;
    z-index:10;
    font-family: 'bodoni-regular';
    font-size:50px;
    outline:none;
    border:0px;
    &:focus{
        outline:none;
        border:0px;
    }
    @media only screen and (max-width: 479px) {
        // display:none !important;
    }
`;
const ArrowHolderRight = styled(ArrowHolderLeft)`
    left:auto;
    right:0px;
`;
export default (props) => (
  <StaticQuery
    query={graphql`
      query {
            allWpPost(sort: {order: DESC, fields: date}) {
                nodes {
                title
                content
                date
                slug
                excerpt
                summary {
                  summary
                  tileImage {
                      mediaItemUrl
                      altText
                    }
                }
                categories {
                    nodes {
                    slug
                    name
                    }
                }
                tags {
                    nodes {
                    slug
                    name
                    }
                }
                featuredImage {
                    node {
                    mediaItemUrl
                    }
                }
                }
            }
          }
      `}

    render={data => (
      <SliderSection data={data} {...props} />

    )}
  />
)
const SliderSection = (props) => {
  const { data } = props;
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y]}
      spaceBetween={20}
      loop={false}
      slidesPerView={3}
      style={{ position: "relative" }}
      breakpoints={{
        991: {
          slidesPerView: 3
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 2
        },
        225: {
          slidesPerView: 1,
          spaceBetween: 40
        },
      }}
      // autoplay={{
      //     delay: 4500,
      //     disableOnInteraction: false,
      // }}
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      onBeforeInit={(swiper) => {
        swiper.params.navigation.prevEl = navigationPrevRef.current;
        swiper.params.navigation.nextEl = navigationNextRef.current;
      }}
      className="w-[95%] h-auto reports-slider"
      style={{ marginLeft: '0px', marginRight: '0px', position: 'unset', marginTop: "3em" }}
    >
      {data.allWpPost.nodes.map(({ node }, index) => (
        props.case == "true" ?
          data.allWpPost.nodes[index].categories.nodes[0].name == "Case Study" ?
            <SwiperSlide key={"atHomeSlide" + index} key={data.allWpPost.nodes[index].slug}>
              <Link to={data.allWpPost.nodes[index].categories.nodes[0].name == "Case Study" ? "/project/" + data.allWpPost.nodes[index].slug + "/" : "/journal/" + data.allWpPost.nodes[index].slug + "/"} className="text-deco-none">
                <Slide homePage={props.homePage} data={data.allWpPost.nodes[index]}></Slide>
              </Link>
            </SwiperSlide>
            : ''
          :
          data.allWpPost.nodes[index].categories.nodes[0].name == "Journal" ?
            <SwiperSlide key={"atHomeSlide" + index} key={data.allWpPost.nodes[index].slug}>
              <Link to={data.allWpPost.nodes[index].categories.nodes[0].name == "Case Study" ? "/project/" + data.allWpPost.nodes[index].slug + "/" : "/journal/" + data.allWpPost.nodes[index].slug + "/"} className="text-deco-none">
                <Slide homePage={props.homePage} data={data.allWpPost.nodes[index]}></Slide>
              </Link>
            </SwiperSlide>
            : ''
      ))}

      <ArrowHolderLeft ref={navigationPrevRef} className="focusNone">
        &#60;
      </ArrowHolderLeft>
      <ArrowHolderRight ref={navigationNextRef} className="focusNone">
        &#62;
      </ArrowHolderRight>
    </Swiper>
  )
};
